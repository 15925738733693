import React from 'react'
import ContactUs from '../molecules/ContactUs'
import OutletList from '../molecules/OutletList'
import JoinUsForm from './JoinUsForm'

const Outlets = ({ searchQuery }) => {
  return (
    <div>
      <div className='relative' data-aos="zoom-in" data-aos-duration="1500">
        <div className='absolute inset-0 bg-black opacity-70'></div>
        <img src='/images/outlets-bg.jpg' alt='carousel' className='w-full aspect-[2] object-cover h-auto 2xl:h-[700px]' />
        <div className='absolute inset-0 flex justify-center items-center '>
          <p className='text-white text-center text-[8vw] font-roboto font-bold '>Outlets</p>
        </div>
      </div>
      <div className='mt-[100px] max-sm:mt-[50px] px-[50px] max-sm:px-[30px]'>
        <OutletList searchQuery={searchQuery} />
      </div>
      <div className='mt-[100px] max-sm:mt-[50px]'>
        <JoinUsForm />
      </div>
      <ContactUs />
    </div>
  )
}

export default Outlets
