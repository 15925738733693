import React from 'react';
import './style.module.scss'

const Category = ({ key, bgColor, title, image, outlineColor, textColor, onclick, activeTab }) => {
    return (
        <div onClick={onclick} className={`${bgColor} h-[421px]  w-[294px] max-xs:h-[200px]  max-xs:w-[130px] max-sm:h-[210px]  max-sm:w-[148px] max-lg:h-[230px] max-lg:w-[150px] max-xl:h-[250px] max-xl:w-[180px] max-2xl:h-[300px] max-2xl:w-[225px] rounded-[200px]  flex flex-col items-center justify-center transition ease-in-out duration-300 transform hover:scale-105 ${activeTab ? 'border-[#EA6121] border-2' : "border-[#fff] border-0"}`}>
            <img
                src={image}
                alt='category-item'
                className={`outline outline-4 rounded-[50%] ${outlineColor} max-xs:h-[90px] max-xs:w-[90px] max-sm:h-[100px] max-sm:w-[100px] max-lg:h-[100px] max-lg:w-[100px] max-xl:h-[110px] max-xl:w-[110px] max-2xl:h-[140px] max-2xl:w-[140px]  h-[210px] w-[210px]`}
            />
            <p className={`${textColor} text-[39px] max-sm:text-[16px]  max-lg:text-[20px]   max-2xl:text-[25px] py-8  max-sm:py-[14px]  max-2xl:py-5 font-roboto font-semibold`}>{title}</p>
        </div>

    )
}

export default Category