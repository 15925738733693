import { message } from 'antd';
import React, { useState } from 'react'
import ButtonComponent from '../../atoms/Button';
import { commonConstants } from '../../utils/constants';
import styles from './style.module.scss'

const JoinUsForm = () => {
    const [formData, setFormData] = useState({
        FullName: '',
        Subject: '',
        // Email: '',
        // PhoneNumber: '',
        // Message: ''
    })

    const [loading, setLoading] = useState(false);

    const onChangeForm = (e) => {
        const { value, name } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const onSubmit = async (e) => {
        try {
            setLoading(true);
            e.preventDefault();
            const sheetFormData = new FormData();

            sheetFormData.append("FullName", formData?.FullName);
            sheetFormData.append("Subject", formData?.Subject);
            // sheetFormData.append("Email", formData?.Email);
            // sheetFormData.append("PhoneNumber", formData?.PhoneNumber);
            // sheetFormData.append("Message", formData?.Message);

            const res = await fetch(commonConstants.google_sheet_deployed_url, {
                method: "POST",
                body: sheetFormData
            });

            setLoading(false);
            setFormData({
                FullName: '',
                Subject: '',
                // Email: '',
                // PhoneNumber: '',
                // Message: ''
            });

            if (res.status === 200) {
                message.success({
                    content: "Thank you for showing interest in us, We will contact you soon."
                })
            }

        } catch (error) {
            console.log(error);
        }
    }

    const isButtonDisabled = !formData.FullName || !formData.PhoneNumber;
    // || !formData.Subject || !formData.Email 


    return (
        <div className='sm:px-[10%] px-[0px] py-[4%] [background:_linear-gradient(180deg,_rgba(149,_149,_149,_0.08)_0%,_rgba(149,_149,_149,_0.2)_51.94%,_rgba(149,_149,_149,_0.08)_100%)] flex flex-col gap-5 items-center justify-center'>
            <span className='text-[37px] max-sm:text-[20px] font-[700] leading-[47px] mb-[70px] max-sm:mb-[10px]'>
                Contact Us
            </span>
            <div className='w-[70%]'>
                <div className='flex gap-10 max-sm:gap-6 flex-col justify-center items-center'>
                    <div data-aos="fade-left" className='sm:flex flex-col justify-center items-center gap-6 w-[100%]'>
                        <label className='flex flex-col gap-2 w-[48%] max-xl:w-[100%] max-2xl:w-[80%]'>
                            <span className='text-[17px] max-sm:text-[14px] leading-[21px] font-[400] font-sans'> Full Name</span>
                            <input onChange={onChangeForm} value={formData?.FullName} className={`${styles.themeInput}`} type="text" name="FullName" placeholder='Full Name' />
                        </label>
                        <label className='flex flex-col gap-2 w-[48%] max-xl:w-[100%] max-sm:mt-6 max-2xl:w-[80%]' >
                            <span className='text-[17px] max-sm:text-[14px] leading-[21px] font-[400] font-sans'> Phone Number</span>
                            <input onChange={onChangeForm} value={formData?.PhoneNumber} className={`${styles.themeInput}`} type="number" name="PhoneNumber" placeholder='Phone Number' />
                        </label>
                    </div>
                    {/* <div data-aos="fade-left" className='sm:flex gap-6 w-[100%]'>
                        <label className='flex flex-col gap-2 w-[48%] max-sm:w-[100%]'>
                            <span className='text-[17px] max-sm:text-[14px] leading-[21px] font-[400] font-sans'> Email ID</span>
                            <input onChange={onChangeForm} value={formData?.Email} className={`${styles.themeInput}`} type="email" name="Email" placeholder='Email Id' />
                        </label>
                        <label className='flex flex-col gap-2 w-[48%] max-sm:w-[100%] max-sm:mt-6' >
                            <span className='text-[17px] max-sm:text-[14px] leading-[21px] font-[400] font-sans'> Subject</span>
                            <input onChange={onChangeForm} value={formData?.Subject} className={`${styles.themeInput}`} type="text" name="Subject" placeholder='Subject' />
                        </label>
                    </div>
                    <div data-aos="fade-left" className='w-[100%] flex flex-col gap-2'>
                        <div className="text-[17px] max-sm:text-[14px] leading-[21px] font-[400] font-sans">Message</div>
                        <textarea
                            id={''}
                            onChange={onChangeForm}
                            value={formData?.Message}
                            name="Message"
                            rows={5}
                            placeholder='Type here...'
                            className={`w-[100%] ${styles.themeTextArea}`}>
                        </textarea>
                    </div> */}
                    <div data-aos="fade-left" className='mt-[40px]'>
                        <ButtonComponent disabled={isButtonDisabled} loading={loading} iconClassName="absolute right-[15px] max-sm:right-[35px]" iconPath="/svg/right-white-arrow.svg" labelClassname="text-[24px] max-sm:text-[20px] ml-[-10px] leading-[24px] font-[500] font-sans" inputStyles={{
                            minWidth: '220px', width: '220px', height: '60px', boxShadow: 'none'
                        }} label={"Submit"} onClick={onSubmit} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinUsForm