import React from 'react';
import styles from './style.module.scss';
import Lottie from 'lottie-react';
import whiteLoader from '../../lotties/white-loader.json';

const ButtonComponent = (props) => {
  const {
    name,
    label,
    disabled,
    onClick,
    inputStyles,
    iconPath,
    iconClassName,
    className,
    loading,
    labelClassname,
    ...rest
  } = props;
  return (
    <button
      name={name}
      disabled={disabled || loading}
      onClick={onClick}
      className={`${className ? className : ''} ${styles.button}`}
      style={inputStyles}
      {...rest}
    >
      {
        loading ?
          <Lottie
            className={`${styles.loaderStyle}`}
            animationData={whiteLoader}
            loop={true}
          />
          :
          <>
            <span className={labelClassname || ""}>
              {label}
            </span>
            {iconPath && <img className={iconClassName} src={iconPath} alt='button' />}
          </>
      }
    </button>
  );
};

export default ButtonComponent;
