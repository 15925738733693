import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from './organisms/Header/Header'
import AppRoutes from './routes';
import Footer from './organisms/Footer'
import './app.css'

const App = () => {

  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    AOS.init({
      duration: window.innerWidth > 1024 ? 1000 : ""
    });
  }, []);

  return (
    <div className='min-h-screen '>
      <Header setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
      <AppRoutes searchQuery={searchQuery} />
      <Footer />
    </div>
  )
}

export default App
