import React from 'react'
import { useMediaQuery } from '@mui/material';
import ButtonComponent from '../../atoms/Button'
import { useNavigate } from 'react-router-dom'

const ContactUs = () => {
    const navigate = useNavigate()

    const isMobile = useMediaQuery('(max-width:768px)');

    return (
        <div data-aos="fade-up" className='sm:flex items-center gap-[250px] max-lg:gap-[150px] sm:mt-[150px] mt-[50px] mb-[50px] sm:mb-[120px] mx-auto w-max py-[20px] sm:py-[40px] px-[40px] rounded-[30px] shadow-[0px_40px_60px_0px_#F160221A] bg-white'>
            <span className='text-[#202020] text-[28px] max-sm:text-[20px] leading-[35px] font-[600]'>
                Need help locating us?
            </span>
            <div className="max-sm:flex justify-center">
                <ButtonComponent iconClassName="absolute right-[10px]" iconPath="/svg/right-white-arrow.svg" labelClassname={`sm:text-[18px] text-[16px] ml-[-15px] sm:ml-[-25px]  leading-[24px] sm:leading-[28px] font-[700]`} inputStyles={{
                    minWidth: isMobile ? '170px' : '220px',
                    width: isMobile ? '170px' : '220px',
                    height: isMobile ? '40px' : '60px',
                    boxShadow: 'none'
                }} label={"CONTACT US"} onClick={() => { navigate('/contact') }} />
            </div>
        </div>
    )
}

export default ContactUs