import React from 'react'
import JoinUsForm from './JoinUsForm'
import Map from '../molecules/Map'

const Contact = () => {
  return (
    <div>
      <div className='relative mb-[100px] max-sm:mb-[30px]' data-aos="zoom-in" data-aos-duration="1500">
        <div className='absolute inset-0 bg-black opacity-70'></div>
        <img src='/svg/logo-banner.svg' alt='carousel' className='w-full aspect-[2] object-cover h-auto 2xl:h-[700px]' />
        <div className='absolute inset-0 flex justify-center items-center '>
          <p className='text-white text-center text-[8vw] font-roboto font-bold'>Contact Us</p>
        </div>
      </div>
      <JoinUsForm />
      <Map />
    </div>
  )
}

export default Contact
