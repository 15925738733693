import React from 'react';
import './Loader.scss'

const Loader = () => {
    return (
        <div className=' w-full text-center h-[50vh] flex items-center justify-center'>
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loader
