import React from 'react'

const AboutMenuItem = () => {
    return (
        <div className='inline-grid gap-4 grid-cols-3 max-lg:grid-cols-1 w-full'>
            <div data-aos="fade-right" className='flex flex-col gap-4 p-5 text-center items-center border-2 border-[#DEDEDE] rounded-[40px]'>
                <img className='aspect-[3/2] rounded-[30px] object-cover w-full' src='/images/item-gulab-halwa.jpg' alt=""/>
                <span className='text-[31px] max-xl:text-[27px] max-sm:text-[22px] sm:leading-[41px] font-[700]'>
                    Gulab Halwa
                </span>
                <span className='text-[23px] max-xl:text-[20px] max-sm:text-[16px] sm:leading-[30px] font-[400]'>
                    Gulab Halwa features a mix
                    of Tahini, milk, rose water,
                    orange blossom, rose petal &
                    sugar which creates a
                    mesmerizing texture. It is a
                    delicious dessert item that is
                    best when chilled.
                </span>
            </div>
            <div data-aos="zoom-in" className='flex flex-col gap-4 p-5 text-center items-center border-2 border-[#DEDEDE] rounded-[40px]'>
                <img className='aspect-[3/2] rounded-[30px] object-cover w-full' src='/images/Dry-fruit-mithai-img/Gulkand Katli(7).webp' alt=""/>
                <span className='text-[31px] max-xl:text-[27px]   max-sm:text-[22px] sm:leading-[41px] font-[700]'>
                    GulKand Katli
                </span>
                <span className='text-[23px] max-xl:text-[20px] max-sm:text-[16px] sm:leading-[30px] font-[400]'>
                    Gulkand katli is thin,
                    smooth and melt in your mouth! In
                    this recipe, Rose petal jam and
                    crushed pistachios are stuffed or
                    between two almond thins. A
                    perfect sweet for any special day
                </span>
            </div>
            <div data-aos="fade-left" className='flex flex-col gap-4 p-5 text-center items-center border-2 border-[#DEDEDE] rounded-[40px]'>
                <img className='aspect-[3/2] rounded-[30px] object-cover w-full' src='/images/Bengali-Mithai/Rasgulla(1).webp' alt=""/>
                <span className='text-[31px] max-xl:text-[27px]  max-sm:text-[22px] sm:leading-[41px] font-[700]'>
                    Rasgulla
                </span>
                <span className='text-[23px] max-xl:text-[20px] max-sm:text-[16px] sm:leading-[30px] font-[400]'>
                    Rasgulla is a syrupy dessert popular
                    in the Bengal region of the Indian
                    subcontinent. It is made from ball-
                    shaped dumplings of chhena and
                    semolina dough, cooked in light
                    sugar syrup made of sugar.
                </span>
            </div>
        </div>
    )
}

export default AboutMenuItem