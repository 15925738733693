import React from 'react'

const Map = () => {

  return (
    <div data-aos="zoom-in" className="mt-[100px] max-2xl:my-[50px]" >
      <iframe title='map' className="border-none w-full h-[1000px] max-md:h-[300px] max-2xl:h-[500px]"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7185.792295997487!2d73.31823425489338!3d25.773993591326196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x394273069517a0e9%3A0x2069e34951e653c3!2sGulab%20Halwa%20Wala!5e0!3m2!1sen!2sin!4v1716962669482!5m2!1sen!2sin"
        allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

export default Map
