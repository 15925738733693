import React from 'react'
import { commonConstants } from '../utils/constants';
import { motion } from 'framer-motion';

const Footer = () => {
    const scrollToTopFunction = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <div className='mt-auto'>
            <div className="bg-themeUltraLight sm:p-[50px] relative  ">
                <div className='flex flex-col lg:flex-row items-center lg:items-start justify-around p-4 gap-[40px] sm:gap-auto'>
                    <div className='flex flex-col justify-start items-center lg:items-start sm:gap-2 '>
                        <img data-aos="fade-up" alt='' src='/svg/logo.svg' className='h-[100px] w-[200px] sm:w-auto sm:h-auto' />
                        <span className='font-[600] text-[12px] lg:text-[18px] sm:text-[22px] leading-[33px] lg:mt-2 text-wrap' data-aos="fade-up" >
                            Traditional Indian Sweets Crafted with Love.
                        </span>
                        <div className='flex gap-2 mt-[10px] lg:mt-[30px]'>
                            <a data-aos="fade-up" href='https://www.facebook.com/gulabhalwa.palimarwar/' rel="noreferrer" target='_blank' className='rounded-[50%] cursor-pointer flex items-center justify-center border-[1px] border-black w-[40px] h-[40px] sm:w-[50px] sm:h-[50px]' >
                                <img alt='facebook' src='/svg/facebook-black.svg' />
                            </a>
                            <a data-aos="fade-up" href='/' rel="noreferrer" target='_blank' className='rounded-[50%] cursor-pointer flex items-center justify-center border-[1px] border-black w-[40px] h-[40px] sm:w-[50px] sm:h-[50px]'>
                                <img alt='twitter' src='/svg/twitter-black.svg' />
                            </a>
                            <a data-aos="fade-up" href='https://www.instagram.com/gulabhalwawala/' rel="noreferrer" target='_blank' className='rounded-[50%] cursor-pointer flex items-center justify-center border-[1px] border-black w-[40px] h-[40px] sm:w-[50px] sm:h-[50px]'>
                                <img alt='instagram' src='/svg/instagram-black.svg' />
                            </a>
                        </div>
                    </div>
                    <div className='lg:grid lg:grid-cols-2 flex flex-row-reverse justify-between items-baseline w-full lg:w-auto 2xl:gap-[100px]'>

                        <div className='flex flex-col gap-1'>
                            <span data-aos="fade-up" className='font-[700] mb-4 sm:mb-5 leading-[24px] sm:leading-[28px] max-md:text-[18px] text-[22px] max-lg:text-[24px]'>
                                Useful Links
                            </span>
                            <a data-aos="fade-up" href='/outlets' className='hover:text-theme font-[600] leading-[25px] sm:leading-[33px] max-sm:text-[12px] max-lg:text-[20px] text-[18px] cursor-pointer'>
                                Our Shops
                            </a>
                            <a data-aos="fade-up" href='/' className='hover:text-theme font-[600] leading-[25px] sm:leading-[33px] max-sm:text-[12px] max-lg:text-[20px] text-[18px]cursor-pointer'>
                                Our Products
                            </a>
                            <a data-aos="fade-up" href='/about' className='hover:text-theme font-[600] leading-[25px] sm:leading-[33px] max-sm:text-[12px] max-lg:text-[20px] text-[18px]cursor-pointer'>
                                About us
                            </a>
                            <a data-aos="fade-up" href='/gallery' className='hover:text-theme font-[600] leading-[25px] sm:leading-[33px] max-sm:text-[12px] max-lg:text-[20px] text-[18px]cursor-pointer'>
                                Gallery
                            </a>
                        </div>

                        <div className='flex flex-col gap-1 sm:gap-2 '>
                            <span className='font-[700] mb-4 sm:mb-5 leading-[28px] max-md:text-[18px] text-[22px] max-lg:text-[24px]' data-aos="fade-up">
                                Contact info
                            </span>
                            <div className='flex gap-2 sm:gap-3 items-center' data-aos="fade-up">
                                <img alt='phone' src='/svg/phone-black.svg' className="sm:h-auto h-[14px]" />
                                <a className='font-[600] hover:text-theme cursor-pointer leading-[28px] sm:leading-[33px]  max-sm:text-[12px] max-lg:text-[20px] text-[18px]' href={`tel:${commonConstants.company_number}`}>
                                    {commonConstants.company_number}
                                </a>
                            </div>
                            <div className='flex gap-2 sm:gap-3 items-center' data-aos="fade-up">
                                <img alt='email' src='/svg/email-black.svg' className="sm:h-auto h-[14px]" />
                                <a className='font-[600] hover:text-theme cursor-pointer leading-[28px] sm:leading-[33px]  max-sm:text-[12px] max-lg:text-[20px] text-[18px]' href={`mailto:${commonConstants.company_email}`}>
                                    {commonConstants.company_email}
                                </a>
                            </div>
                            <div className='flex gap-2 sm:gap-3 items-center pl-[4px] sm:max-w-[350px]' data-aos="fade-up">
                                <img alt='location' src='/svg/location-black.svg' className="sm:h-auto h-[14px]" />
                                <span className='font-[600] leading-[28px] sm:leading-[33px] pl-[4px]  max-sm:text-[12px] max-lg:text-[20px] text-[18px]'>
                                    {commonConstants.company_address}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex items-end justify-end' onClick={scrollToTopFunction} id='myBtn' >
                    <div className='flex items-center justify-center w-[66px] h-[66px] max-sm:w-[40px] max-sm:h-[40px] bg-theme-gradient rounded-[50%] cursor-pointer order-last max-sm:mr-6 max-sm:mb-4'>
                        <motion.img animate={{ y: [0, -10, 0] }} transition={{ repeat: Infinity, duration: 1 }} alt='scrollToTop' src='/svg/scrollToTop-icon.svg' className=' max-sm:h-[30px]' />
                    </div>
                </div>
            </div>
            <div className='bg-theme-gradient flex flex-col lg:flex-row justify-between items-center lg:px-8 py-[12px]'>
                <div className='flex '>
                    <img alt='copyright' src='/svg/copyright.svg' />
                    <p className='font-[600] text-[20px] max-sm:text-[14px] text-white leading-[33px] ml-2'>
                        2024 GULAB HALWA WALA | All rights reserved
                    </p>
                </div>
                <div className='font-[600] text-[20px] max-sm:text-[14px] text-white leading-[33px] ml-2'>Managed by: <a href='https://thecodingstudio.in/' target='_blank' rel="noreferrer">theCodingStudio.in</a></div>
            </div>
        </div>
    )
}

export default Footer
