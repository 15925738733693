export const commonConstants = {
    google_sheet_deployed_url: "https://script.google.com/macros/s/AKfycby-oXVaZ2lF8dzRFHqUfleYld1EgxMuWddYcRgX6jVjDThTFbK5JnsP0XXtp32bFqgr/exec",
    company_name: 'Gulab Halwa Wala',
    company_welcome_title: 'Welcome to Gulab Halwa Wala',
    company_number: '02932223550',
    company_email: 'gulabhalwawala@gmail.com',
    company_address: 'Gol Nimbra, Pali',
    headerItems: [
        {
            name: 'Home',
            key: 'home',
            path: '/',
            type: 'link'
        },
        {
            name: 'About',
            key: 'aboutUs',
            path: '/about',
            type: 'link'
        },
        {
            name: 'Outlets',
            key: 'outlets',
            path: '/outlets',
            type: 'link'
        },
        {
            name: 'Gallery',
            key: 'gallery',
            path: '/gallery',
            type: 'link'
        },
        {
            name: 'Contact',
            key: 'contact',
            path: '/contact',
            type: 'link'
        }
    ],
    headerButtons: [
        {
            name: 'Search',
            key: 'search',
            img: '/svg/search.svg',
        },
        {
            name: 'Menu',
            key: 'menu',
            img: '/svg/menu.svg',
        }
    ],
    homeCarousel: [
        {
            key: 1,
            title: 'Flourished Fantasy Halwa',
            image: '/images/BannerImages/Gulab-Halwa1.webp'
        },
        {
            key: 2,
            title: 'Dry Fruits Mithai',
            image: '/images/Dry-fruit-mithai-img/Anjeer Chakra(1).webp',
        },
        {
            key: 3,
            title: 'Mava Mithai',
            image: '/images/BannerImages/Chocolate Barfi .JPG'
        },
        {
            key: 4,
            title: 'Desi Ghee Mithai',
            image: '/images/Desi-Ghee-Mithai/Ghevar(9).webp',
        },
        {
            key: 5,
            title: 'Bengali Mithai',
            image: '/images/Bengali-Mithai/Rajbhog(2).webp',
        },
        {
            key: 6,
            title: 'Namkeen',
            image: '/images/Namkeens/Tikha Jodhpur Mixer(1).webp',
        },

    ],
    happyCustomers: [
        {
            name: "Amit Singh",
            description: "If you're looking for some tasty gulab halwa and other sweets . Then definitely visit this store with quality halwa in minimal price . If you're new to jodhpur or just visiting here then definitely goto this place and try the very old shop with amazing taste.",
            // image: "/images/happy-customer.jpg"
        },
        {
            name: "Amit Patni",
            description: "Awesome taste and reasonable price. If u want to eat Gulab Halwa, Go only for this shop",
            // image: "/images/happy-customer.jpg"
        },
        {
            name: "Shaillender Solanki",
            description: "Gulab Halwa Taste is Really Worth made of Pure Desi Ghee, Even After Traveling from Rajsthan To Mumbai Taste Remains The Same",
            // image: "/images/happy-customer.jpg"
        },
        {
            name: "Sourabh Kumbhat",
            description: "Gulab halwa is famous by the name of pali ka gulab halwa.Delicious taste with flavour of rose and very different ingredients.I suggest once you taste it.",
            // image: "/images/happy-customer.jpg"
        },
        {
            name: "Nitin Kulkarni",
            description: "The best place located on main B road in Jodhpur for getting fresh and tasty Pali's famous Gulab Halwa ( A milk based Kalakand preparation ) on any day. A must try for all sweets lovers 🙂",
            // image: "/images/happy-customer.jpg"
        },
        {
            name: "Sudeep Singhvi",
            description: "One of the best shops to purchase sweets. Originally receive is of Pali but popular all over the world. A must try.",
            // image: "/images/happy-customer.jpg"
        },
        {
            name: "Meet Durgesh",
            description: "Having speciality of milk cake as a brand name of Gulab halwa which is amazing in taste.",
            // image: "/images/happy-customer.jpg"
        },
        {
            name: "Bharat Chelani",
            description: "Delicious and authentic indian sweet made of rose petals, milk and other ingredients. A must have dessert when you are around.",
            // image: "/images/happy-customer.jpg"
        }
    ],
    homeCardMenuItems: [
        {
            key: 1,
            name: 'Freshly Picked Gulab Jamun',
            description: 'Gulab Jamun is a sweet, viscous food substance made by Gulab halwa',
            bg_img: '/images/gulab-jamun.png'
        },
        {
            key: 2,
            name: 'Chocolate Ladoo',
            description: 'Chocolate Ladoo is a sweet, viscous food substance made by Gulab halwa',
            bg_img: '/images/Dry-fruit-mithai-img/Chocolate Ladoo(2).webp'
        },
        {
            key: 3,
            name: 'Chandra Kala',
            description: 'Chandra Kala is a sweet, viscous food substance made by Gulab halwa',
            bg_img: '/images/Desi-Ghee-Mithai/Chandra Kala(7).webp'
        },
        {
            key: 4,
            name: 'Freshly Tasted Gulab Halwa',
            description: 'Gulab Halwa is a sweet, viscous food substance made by Gulab halwa',
            bg_img: '/images/fresh-gulab-halwa.png'
        },
    ],
    topPicksCategory: [
        {
            key: 1,
            image: '/svg/category-top-picks/halwa-with-honey.svg',
            title: 'Halwa',
            bgColor: 'bg-[#7BB4DD4D]',
            outlineColor: 'outline-[#5D9FCE]',
            txtColor: 'text-[#0E609A]',
            items: [
                {
                    key: 1,
                    image: '/images/Gulab-Halwa.-2.webp',
                    title: 'Halwa'
                },
                {
                    key: 2,
                    image: '/images/Gulab-Halwa.-_3.webp',
                    title: 'Halwa'
                }
            ]
        },
        {
            key: 2,
            image: '/svg/category-top-picks/sweets.svg',
            title: 'Sweet',
            bgColor: 'bg-[#FFC6AC4D]',
            outlineColor: 'outline-[#FFA780]',
            txtColor: 'text-[#D6602A]',
            items: [
                {
                    key: 1,
                    image: '/images/Mawa-Mithai/Badam Penda(1).webp',
                    title: 'Badam Penda'
                },
                {
                    key: 2,
                    image: '/images/Mawa-Mithai/Barfi(2).webp',
                    title: 'Barfi'
                },
                {
                    key: 3,
                    image: '/images/Mawa-Mithai/GunjaPetha(3).webp',
                    title: 'GunjaPetha'
                },
            ]
        },
        {
            key: 3,
            image: '/svg/category-top-picks/dry-fruits.svg',
            title: 'Dry Fruits',
            bgColor: 'bg-[#F9CF584D]',
            outlineColor: 'outline-[#EAC96A]',
            txtColor: 'text-[#AF8409]',
            items: [
                {
                    key: 1,
                    image: '/images/Dry-fruit-mithai-img/Anjeer Chakra(1).webp',
                    title: 'Anjeer Chakra'
                },
                {
                    key: 2,
                    image: '/images/Dry-fruit-mithai-img/Chocolate Ladoo(2).webp',
                    title: 'Chocolate Ladoo'
                },
                {
                    key: 3,
                    image: '/images/Dry-fruit-mithai-img/Gulkand Ladoo(3).webp',
                    title: 'Gulkand Ladoo'
                },
                {
                    key: 4,
                    image: '/images/Dry-fruit-mithai-img/Sugar Free(4).webp',
                    title: 'Sugar Free'
                },

            ]
        },
        {
            key: 4,
            image: '/svg/category-top-picks/namkeen.svg',
            title: 'Namkeen',
            bgColor: 'bg-[#C4C1A44D]',
            outlineColor: 'outline-[#B5B07E]',
            txtColor: 'text-[#716816]',
            items: [
                {
                    key: 1,
                    image: '/images/Namkeens/Gathiya(7).webp',
                    title: 'Gathiya'
                },
                {
                    key: 2,
                    image: '/images/Namkeens/Jodhpur Mixer(8).webp',
                    title: 'Jodhpur Mixer'
                },
                {
                    key: 3,
                    image: '/images/Namkeens/Lasan Gathiya(9).webp',
                    title: 'Lasan Gathiya'
                },
                {
                    key: 4,
                    image: '/images/Namkeens/Longi Sev(10).webp',
                    title: 'Longi Sev'
                },
                {
                    key: 5,
                    image: '/images/Namkeens/Moong Dal(11).webp',
                    title: 'Moong Dal'
                },
            ]
        },

    ],
    outlets: [
        {
            landmark: 'Gol Nimbra, Pali',
            address: '7-8, Khatriyon ka Bas, Gol Nimbra, Pali, Rajasthan 306401',
            image: '/images/outlet-1.jpg',
            googleMapLink: 'https://www.google.com/maps/place/Gulab+Halwa+Wala/@25.7737481,73.3230117,19.66z/data=!4m15!1m8!3m7!1s0x394272ffe46fa61f:0xe740c6be76d67a8f!2sGol+Nimbra,+Nadi+Mohalla,+Pali,+Rajasthan+306401!3b1!8m2!3d25.7736963!4d73.3231552!16s%2Fg%2F1pxwglpn4!3m5!1s0x394272fffc6997f7:0xa1598cb1908c371f!8m2!3d25.773556!4d73.3234568!16s%2Fg%2F1261kn5xx?entry=ttu'
        },
        {
            landmark: 'Ambedkar Circle, Pali',
            address: 'S-1, Metro Plaza, Ambedkar Circle, Pali, Rajasthan 306401',
            image: '/images/outlet-2.jpg',
            googleMapLink: 'https://www.google.com/maps/place/Gulab+Halwa+Wala/@25.7781177,73.3267752,17z/data=!3m1!4b1!4m6!3m5!1s0x394273069517a0e9:0x2069e34951e653c3!8m2!3d25.778113!4d73.3316461!16s%2Fg%2F12cp8pfmt?entry=ttu'
        },
        {
            landmark: 'New Bus Stand, Pali',
            address: 'Opposite New bus stand, Sumerpur Road, Pali, Rajasthan 306401',
            image: '/images/outlet-3.jpg',
            goggleMapLink: 'https://www.google.com/maps/place/Gulab+Halwa+Wala/@26.2788154,73.0058067,17z/data=!3m1!4b1!4m6!3m5!1s0x39418c3176f9875d:0x2ab4a4f246d7aa85!8m2!3d26.2788106!4d73.0083816!16s%2Fg%2F124ygj3x0?entry=ttu'
        },
        {
            landmark: 'Sardarpura, Jodhpur',
            address: '238, 1st B Rd, Sardarpura, Jodhpur, Rajasthan 342003',
            image: '/images/outlet-4.jpg'
        },
        {
            landmark: 'Paota, Jodhpur',
            address: 'Opposite New bus stand, Sumerpur Road, Pali, Rajasthan 306401',
            image: '/images/outlet-5.jpg'
        },
        // {
        //     landmark: 'Sardarpura, Jodhpur',
        //     address: '258, 1st B Rd, Sardarpura, Jodhpur, Rajasthan 342003',
        //     image: '/images/outlet-6.jpg'
        // }
    ],
    gallaryProducts: [
        {
            key: 1,
            image: '/images/Gulab-Halwa.-2.webp',
            title: 'All',
            items: [
                {
                    key: 1,
                    image: '/images/Mawa-Mithai/Badam Penda(1).webp',
                    title: 'Badam Penda'
                },
                {
                    key: 2,
                    image: '/images/Mawa-Mithai/Barfi(2).webp',
                    title: 'Barfi'
                },
                {
                    key: 3,
                    image: '/images/Mawa-Mithai/GunjaPetha(3).webp',
                    title: 'GunjaPetha'
                },
                {
                    key: 4,
                    image: '/images/Mawa-Mithai/Chocolate Barfi(4).webp',
                    title: 'Chocolate Barfi'
                },
                {
                    key: 5,
                    image: '/images/Mawa-Mithai/Khopra Barfi(5).webp',
                    title: 'Khopra Barfi'
                },
                {
                    key: 6,
                    image: '/images/Mawa-Mithai/Chudi Penda(6).webp',
                    title: 'Chudi Penda'
                },
                {
                    key: 7,
                    image: '/images/Mawa-Mithai/Hath Penda(7).webp',
                    title: 'Hath Penda'
                },
                {
                    key: 8,
                    image: '/images/Mawa-Mithai/Handi Penda(8).webp',
                    title: 'Handi Penda'
                },
                {
                    key: 9,
                    image: '/images/Mawa-Mithai/Holi Penda(9).webp',
                    title: 'Holi Penda'
                },
                {
                    key: 10,
                    image: '/images/Mawa-Mithai/Kalakand(10).webp',
                    title: 'Kalakand'
                },

                {
                    key: 11,
                    image: '/images/Mawa-Mithai/Kesar Barfi(11).webp',
                    title: 'Kesar Barfi'
                },
                {
                    key: 12,
                    image: '/images/Mawa-Mithai/Kesar Penda(12).webp',
                    title: 'Kesar Penda'
                },
                {
                    key: 13,
                    image: '/images/Mawa-Mithai/Mathura Penda(13).webp',
                    title: 'Mathura Penda'
                },
                {
                    key: 14,
                    image: '/images/Mawa-Mithai/Penda(14).webp',
                    title: 'Penda'
                },
                {
                    key: 15,
                    image: '/images/Mawa-Mithai/Milk Cake(15).webp',
                    title: 'Milk Cake'
                },
                {
                    key: 16,
                    image: '/images/Mawa-Mithai/Gunja Petha(16).webp',
                    title: 'Gunja Petha'
                },
                {
                    key: 17,
                    image: '/images/Mawa-Mithai/Sada Gunja Petha(17).webp',
                    title: 'Sada Gunja Petha'
                },
                {
                    key: 18,
                    image: '/images/Dry-fruit-mithai-img/Anjeer Chakra(1).webp',
                    title: 'Anjeer Chakra'
                },
                {
                    key: 19,
                    image: '/images/Dry-fruit-mithai-img/Chocolate Ladoo(2).webp',
                    title: 'Chocolate Ladoo'
                },
                {
                    key: 20,
                    image: '/images/Dry-fruit-mithai-img/Gulkand Ladoo(3).webp',
                    title: 'Gulkand Ladoo'
                },
                {
                    key: 21,
                    image: '/images/Dry-fruit-mithai-img/Sugar Free(4).webp',
                    title: 'Sugar Free'
                },
                {
                    key: 22,
                    image: '/images/Dry-fruit-mithai-img/Anjeer Katli(5).webp',
                    title: 'Anjeer Katli'
                },
                {
                    key: 23,
                    image: '/images/Dry-fruit-mithai-img/Diamond Cake(6).webp',
                    title: 'Diamond Cake'
                },
                {
                    key: 24,
                    image: '/images/Dry-fruit-mithai-img/Gulkand Katli(7).webp',
                    title: 'Gulkand Katli'
                },
                {
                    key: 25,
                    image: '/images/Dry-fruit-mithai-img/Apple(8).webp',
                    title: 'Apple'
                },
                {
                    key: 26,
                    image: '/images/Dry-fruit-mithai-img/Kaju Katli(9).webp',
                    title: 'Kaju Katli'
                },
                {
                    key: 27,
                    image: '/images/Dry-fruit-mithai-img/Kaju Katli(10).webp',
                    title: 'Kaju Katli'
                },
                {
                    key: 28,
                    image: '/images/Dry-fruit-mithai-img/Kaju Katli(11).webp',
                    title: 'Kaju Katli'
                },
                {
                    key: 29,
                    image: '/images/Dry-fruit-mithai-img/Kaju Katli(12).webp',
                    title: 'Kaju Katli'
                },
                {
                    key: 30,
                    image: '/images/Dry-fruit-mithai-img/Kaju Katli(13).webp',
                    title: 'Kaju Katli'
                },
                {
                    key: 31,
                    image: '/images/Dry-fruit-mithai-img/Kaju Katli(14).webp',
                    title: 'Kaju Katli'
                },
                {
                    key: 32,
                    image: '/images/Dry-fruit-mithai-img/Kaju Pista Jalebi(15).webp',
                    title: 'Kaju Pista Jalebi'
                },
                {
                    key: 33,
                    image: '/images/Dry-fruit-mithai-img/Kaju Pista Samosa(16).webp',
                    title: 'Kaju Pista Samosa'
                },
                {
                    key: 34,
                    image: '/images/Dry-fruit-mithai-img/Kaju Pista Roll(17).webp',
                    title: 'Kaju Pista Roll'
                },
                {
                    key: 35,
                    image: '/images/Dry-fruit-mithai-img/Mango(18).webp',
                    title: 'Mango'
                },
                {
                    key: 36,
                    image: '/images/Dry-fruit-mithai-img/Panch Meva(19).webp',
                    title: 'Panch Meva'
                },
                {
                    key: 37,
                    image: '/images/Dry-fruit-mithai-img/Pineapple(20).webp',
                    title: 'Pineapple'
                },

                {
                    key: 38,
                    image: '/images/Desi-Ghee-Mithai/gulab jamun(1).webp',
                    title: 'Gulab Jamun'
                },
                {
                    key: 39,
                    image: '/images/Desi-Ghee-Mithai/White Thor(2).webp',
                    title: 'White Thor'
                },
                {
                    key: 40,
                    image: '/images/Desi-Ghee-Mithai/Ghevar(3).webp',
                    title: 'Ghevar'
                },
                {
                    key: 41,
                    image: '/images/Desi-Ghee-Mithai/BaluShahi(4).webp',
                    title: 'Balu Shahi'
                },
                {
                    key: 42,
                    image: '/images/Desi-Ghee-Mithai/Boondi Ladoo(5).webp',
                    title: 'Boondi Ladoo'
                },
                {
                    key: 43,
                    image: '/images/Desi-Ghee-Mithai/AnjeerKatli(6).webp',
                    title: 'Anjeer Katli'
                },
                {
                    key: 44,
                    image: '/images/Desi-Ghee-Mithai/Chandra Kala(7).webp',
                    title: 'Chandra Kala'
                },
                {
                    key: 45,
                    image: '/images/Desi-Ghee-Mithai/Choorma Chakki(8).webp',
                    title: 'Choorma Chakki'
                },
                {
                    key: 46,
                    image: '/images/Desi-Ghee-Mithai/Ghevar(9).webp',
                    title: 'Ghevar'
                },
                {
                    key: 47,
                    image: '/images/Desi-Ghee-Mithai/Kale Gulab Jamun(10).webp',
                    title: 'Kale Gulab Jamun'
                },
                {
                    key: 48,
                    image: '/images/Desi-Ghee-Mithai/Mini Mava Kachori(11).webp',
                    title: 'Mini Mava Kachori'
                },
                {
                    key: 49,
                    image: '/images/Desi-Ghee-Mithai/Motichoor Ladoo(12).webp',
                    title: 'Motichoor Ladoo'
                },
                {
                    key: 50,
                    image: '/images/Desi-Ghee-Mithai/Thor(13).webp',
                    title: 'Thor'
                },
                {
                    key: 51,
                    image: '/images/Bengali-Mithai/Rasgulla(1).webp',
                    title: 'Rasgulla'
                },
                {
                    key: 52,
                    image: '/images/Bengali-Mithai/Rajbhog(2).webp',
                    title: 'Rajbhog'
                },
                {
                    key: 53,
                    image: '/images/Bengali-Mithai/Ras Madhuri(3).webp',
                    title: 'Ras Madhuri'
                },
                {
                    key: 54,
                    image: '/images/Bengali-Mithai/Khopra Pak (4).webp',
                    title: 'Khopra Pak'
                },
                {
                    key: 55,
                    image: '/images/Bengali-Mithai/Malai Roll(5).webp',
                    title: 'Malai Roll'
                },
                {
                    key: 56,
                    image: '/images/Bengali-Mithai/Cham Cham(6).webp',
                    title: 'Cham Cham'
                },
                {
                    key: 57,
                    image: '/images/Bengali-Mithai/Lassi(7).webp',
                    title: 'Lassi'
                },
                {
                    key: 58,
                    image: '/images/Bengali-Mithai/Rabri(8).webp',
                    title: 'Rabri'
                },
                {
                    key: 59,
                    image: '/images/Namkeens/Tikha Jodhpur Mixer(1).webp',
                    title: 'Tikha Jodhpur Mixer'
                },
                {
                    key: 60,
                    image: '/images/Namkeens/Boondi Sev(2).webp',
                    title: 'Boondi Sev'
                },
                {
                    key: 61,
                    image: '/images/Namkeens/Barik Gathiya(3).webp',
                    title: 'Barik Gathiya'
                },
                {
                    key: 62,
                    image: '/images/Namkeens/Barik Sev(4).webp',
                    title: 'Barik Sev'
                },
                {
                    key: 63,
                    image: '/images/Namkeens/Barik Sev(5).webp',
                    title: 'Barik Sev'
                },
                {
                    key: 64,
                    image: '/images/Namkeens/Dal Moth(6).webp',
                    title: 'Dal Moth'
                },
                {
                    key: 65,
                    image: '/images/Namkeens/Gathiya(7).webp',
                    title: 'Gathiya'
                },
                {
                    key: 66,
                    image: '/images/Namkeens/Jodhpur Mixer(8).webp',
                    title: 'Jodhpur Mixer'
                },
                {
                    key: 67,
                    image: '/images/Namkeens/Lasan Gathiya(9).webp',
                    title: 'Lasan Gathiya'
                },
                {
                    key: 68,
                    image: '/images/Namkeens/Longi Sev(10).webp',
                    title: 'Longi Sev'
                },
                {
                    key: 69,
                    image: '/images/Namkeens/Moong Dal(11).webp',
                    title: 'Moong Dal'
                },
                {
                    key: 70,
                    image: '/images/Namkeens/Namkeen Badam(12).webp',
                    title: 'Namkeen Badam'
                },
                {
                    key: 71,
                    image: '/images/Namkeens/Namkeen Kaju(13).webp',
                    title: 'Namkeen Kaju'
                },
                {
                    key: 72,
                    image: '/images/Namkeens/Ratlami Sev(14).webp',
                    title: 'Ratlami Sev'
                },
            ]
        },
        {
            key: 2,
            image: '/images/Dry-fruit-mithai-img/Diamond Cake(6).webp',
            title: 'Dry Fruit Mithai',
            items: [
                {
                    key: 1,
                    image: '/images/Dry-fruit-mithai-img/Anjeer Chakra(1).webp',
                    title: 'Anjeer Chakra'
                },
                {
                    key: 2,
                    image: '/images/Dry-fruit-mithai-img/Chocolate Ladoo(2).webp',
                    title: 'Chocolate Ladoo'
                },
                {
                    key: 3,
                    image: '/images/Dry-fruit-mithai-img/Gulkand Ladoo(3).webp',
                    title: 'Gulkand Ladoo'
                },
                {
                    key: 4,
                    image: '/images/Dry-fruit-mithai-img/Sugar Free(4).webp',
                    title: 'Sugar Free'
                },
                {
                    key: 5,
                    image: '/images/Dry-fruit-mithai-img/Anjeer Katli(5).webp',
                    title: 'Anjeer Katli'
                },
                {
                    key: 6,
                    image: '/images/Dry-fruit-mithai-img/Diamond Cake(6).webp',
                    title: 'Diamond Cake'
                },
                {
                    key: 7,
                    image: '/images/Dry-fruit-mithai-img/Gulkand Katli(7).webp',
                    title: 'Gulkand Katli'
                },
                {
                    key: 8,
                    image: '/images/Dry-fruit-mithai-img/Apple(8).webp',
                    title: 'Apple'
                },
                {
                    key: 9,
                    image: '/images/Dry-fruit-mithai-img/Kaju Katli(9).webp',
                    title: 'Kaju Katli'
                },
                {
                    key: 10,
                    image: '/images/Dry-fruit-mithai-img/Kaju Katli(10).webp',
                    title: 'Kaju Katli'
                },
                {
                    key: 11,
                    image: '/images/Dry-fruit-mithai-img/Kaju Katli(11).webp',
                    title: 'Kaju Katli'
                },
                {
                    key: 12,
                    image: '/images/Dry-fruit-mithai-img/Kaju Katli(12).webp',
                    title: 'Kaju Katli'
                },
                {
                    key: 13,
                    image: '/images/Dry-fruit-mithai-img/Kaju Katli(13).webp',
                    title: 'Kaju Katli'
                },
                {
                    key: 14,
                    image: '/images/Dry-fruit-mithai-img/Kaju Katli(14).webp',
                    title: 'Kaju Katli'
                },
                {
                    key: 15,
                    image: '/images/Dry-fruit-mithai-img/Kaju Pista Jalebi(15).webp',
                    title: 'Kaju Pista Jalebi'
                },
                {
                    key: 16,
                    image: '/images/Dry-fruit-mithai-img/Kaju Pista Samosa(16).webp',
                    title: 'Kaju Pista Samosa'
                },
                {
                    key: 17,
                    image: '/images/Dry-fruit-mithai-img/Kaju Pista Roll(17).webp',
                    title: 'Kaju Pista Roll'
                },
                {
                    key: 18,
                    image: '/images/Dry-fruit-mithai-img/Mango(18).webp',
                    title: 'Mango'
                },
                {
                    key: 19,
                    image: '/images/Dry-fruit-mithai-img/Panch Meva(19).webp',
                    title: 'Panch Meva'
                },
                {
                    key: 20,
                    image: '/images/Dry-fruit-mithai-img/Pineapple(20).webp',
                    title: 'Pineapple'
                },
            ]
        },
        {
            key: 3,
            image: '/images/Mawa-Mithai/Kesar Barfi(11).webp',
            title: 'Mawa Mithai',
            items: [
                {
                    key: 1,
                    image: '/images/Mawa-Mithai/Badam Penda(1).webp',
                    title: 'Badam Penda'
                },
                {
                    key: 2,
                    image: '/images/Mawa-Mithai/Barfi(2).webp',
                    title: 'Barfi'
                },
                {
                    key: 3,
                    image: '/images/Mawa-Mithai/GunjaPetha(3).webp',
                    title: 'GunjaPetha'
                },
                {
                    key: 4,
                    image: '/images/Mawa-Mithai/Chocolate Barfi(4).webp',
                    title: 'Chocolate Barfi'
                },
                {
                    key: 5,
                    image: '/images/Mawa-Mithai/Khopra Barfi(5).webp',
                    title: 'Khopra Barfi'
                },
                {
                    key: 6,
                    image: '/images/Mawa-Mithai/Chudi Penda(6).webp',
                    title: 'Chudi Penda'
                },
                {
                    key: 7,
                    image: '/images/Mawa-Mithai/Hath Penda(7).webp',
                    title: 'Hath Penda'
                },
                {
                    key: 8,
                    image: '/images/Mawa-Mithai/Handi Penda(8).webp',
                    title: 'Handi Penda'
                },
                {
                    key: 9,
                    image: '/images/Mawa-Mithai/Holi Penda(9).webp',
                    title: 'Holi Penda'
                },
                {
                    key: 10,
                    image: '/images/Mawa-Mithai/Kalakand(10).webp',
                    title: 'Kalakand'
                },

                {
                    key: 11,
                    image: '/images/Mawa-Mithai/Kesar Barfi(11).webp',
                    title: 'Kesar Barfi'
                },
                {
                    key: 12,
                    image: '/images/Mawa-Mithai/Kesar Penda(12).webp',
                    title: 'Kesar Penda'
                },
                {
                    key: 13,
                    image: '/images/Mawa-Mithai/Mathura Penda(13).webp',
                    title: 'Mathura Penda'
                },
                {
                    key: 14,
                    image: '/images/Mawa-Mithai/Penda(14).webp',
                    title: 'Penda'
                },
                {
                    key: 15,
                    image: '/images/Mawa-Mithai/Milk Cake(15).webp',
                    title: 'Milk Cake'
                },
                {
                    key: 16,
                    image: '/images/Mawa-Mithai/Gunja Petha(16).webp',
                    title: 'Gunja Petha'
                },
                {
                    key: 17,
                    image: '/images/Mawa-Mithai/Sada Gunja Petha(17).webp',
                    title: 'Sada Gunja Petha'
                },
            ]
        },
        {
            key: 4,
            image: '/images/Desi-Ghee-Mithai/Ghevar(9).webp',
            title: 'Desi Ghee Mithai',
            items: [
                {
                    key: 1,
                    image: '/images/Desi-Ghee-Mithai/gulab jamun(1).webp',
                    title: 'Gulab Jamun'
                },
                {
                    key: 2,
                    image: '/images/Desi-Ghee-Mithai/White Thor(2).webp',
                    title: 'White Thor'
                },
                {
                    key: 3,
                    image: '/images/Desi-Ghee-Mithai/Ghevar(3).webp',
                    title: 'Ghevar'
                },
                {
                    key: 4,
                    image: '/images/Desi-Ghee-Mithai/BaluShahi(4).webp',
                    title: 'Balu Shahi'
                },
                {
                    key: 5,
                    image: '/images/Desi-Ghee-Mithai/Boondi Ladoo(5).webp',
                    title: 'Boondi Ladoo'
                },
                {
                    key: 6,
                    image: '/images/Desi-Ghee-Mithai/AnjeerKatli(6).webp',
                    title: 'Anjeer Katli'
                },
                {
                    key: 7,
                    image: '/images/Desi-Ghee-Mithai/Chandra Kala(7).webp',
                    title: 'Chandra Kala'
                },
                {
                    key: 8,
                    image: '/images/Desi-Ghee-Mithai/Choorma Chakki(8).webp',
                    title: 'Choorma Chakki'
                },
                {
                    key: 9,
                    image: '/images/Desi-Ghee-Mithai/Ghevar(9).webp',
                    title: 'Ghevar'
                },
                {
                    key: 10,
                    image: '/images/Desi-Ghee-Mithai/Kale Gulab Jamun(10).webp',
                    title: 'Kale Gulab Jamun'
                },
                {
                    key: 11,
                    image: '/images/Desi-Ghee-Mithai/Mini Mava Kachori(11).webp',
                    title: 'Mini Mava Kachori'
                },
                {
                    key: 12,
                    image: '/images/Desi-Ghee-Mithai/Motichoor Ladoo(12).webp',
                    title: 'Motichoor Ladoo'
                },
                {
                    key: 13,
                    image: '/images/Desi-Ghee-Mithai/Thor(13).webp',
                    title: 'Thor'
                },
            ]
        },
        {
            key: 5,
            image: '/images/Bengali-Mithai/Malai Roll(5).webp',
            title: 'Bengali Mithai',
            items: [
                {
                    key: 1,
                    image: '/images/Bengali-Mithai/Rasgulla(1).webp',
                    title: 'Rasgulla'
                },
                {
                    key: 2,
                    image: '/images/Bengali-Mithai/Rajbhog(2).webp',
                    title: 'Rajbhog'
                },
                {
                    key: 3,
                    image: '/images/Bengali-Mithai/Ras Madhuri(3).webp',
                    title: 'Ras Madhuri'
                },
                {
                    key: 4,
                    image: '/images/Bengali-Mithai/Khopra Pak (4).webp',
                    title: 'Khopra Pak'
                },
                {
                    key: 5,
                    image: '/images/Bengali-Mithai/Malai Roll(5).webp',
                    title: 'Malai Roll'
                },
                {
                    key: 6,
                    image: '/images/Bengali-Mithai/Cham Cham(6).webp',
                    title: 'Cham Cham'
                },
                {
                    key: 7,
                    image: '/images/Bengali-Mithai/Lassi(7).webp',
                    title: 'Lassi'
                },
                {
                    key: 8,
                    image: '/images/Bengali-Mithai/Rabri(8).webp',
                    title: 'Rabri'
                },
            ]
        },
        {
            key: 6,
            image: '/images/Namkeens/Jodhpur Mixer(8).webp',
            title: 'Namkeens',
            items: [
                {
                    key: 1,
                    image: '/images/Namkeens/Tikha Jodhpur Mixer(1).webp',
                    title: 'Tikha Jodhpur Mixer'
                },
                {
                    key: 2,
                    image: '/images/Namkeens/Boondi Sev(2).webp',
                    title: 'Boondi Sev'
                },
                {
                    key: 3,
                    image: '/images/Namkeens/Barik Gathiya(3).webp',
                    title: 'Barik Gathiya'
                },
                {
                    key: 4,
                    image: '/images/Namkeens/Barik Sev(4).webp',
                    title: 'Barik Sev'
                },
                {
                    key: 5,
                    image: '/images/Namkeens/Barik Sev(5).webp',
                    title: 'Barik Sev'
                },
                {
                    key: 6,
                    image: '/images/Namkeens/Dal Moth(6).webp',
                    title: 'Dal Moth'
                },
                {
                    key: 7,
                    image: '/images/Namkeens/Gathiya(7).webp',
                    title: 'Gathiya'
                },
                {
                    key: 8,
                    image: '/images/Namkeens/Jodhpur Mixer(8).webp',
                    title: 'Jodhpur Mixer'
                },
                {
                    key: 9,
                    image: '/images/Namkeens/Lasan Gathiya(9).webp',
                    title: 'Lasan Gathiya'
                },
                {
                    key: 10,
                    image: '/images/Namkeens/Longi Sev(10).webp',
                    title: 'Longi Sev'
                },
                {
                    key: 11,
                    image: '/images/Namkeens/Moong Dal(11).webp',
                    title: 'Moong Dal'
                },
                {
                    key: 12,
                    image: '/images/Namkeens/Namkeen Badam(12).webp',
                    title: 'Namkeen Badam'
                },
                {
                    key: 13,
                    image: '/images/Namkeens/Namkeen Kaju(13).webp',
                    title: 'Namkeen Kaju'
                },
                {
                    key: 14,
                    image: '/images/Namkeens/Ratlami Sev(14).webp',
                    title: 'Ratlami Sev'
                },
            ]
        },
    ],
}