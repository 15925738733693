import React from 'react'
import AboutMenuItem from '../molecules/AboutMenuItem'
import AboutSpecialProducts from '../molecules/AboutSpecialProducts'
import ContactUs from '../molecules/ContactUs'
import HappyCustomers from '../molecules/HappyCustomers'
import JoinUsForm from './JoinUsForm'

const About = () => {
    return (
        <div>
            <div className='relative' data-aos="zoom-in" data-aos-duration="1500">
                <div className='absolute inset-0 bg-black opacity-70'></div>
                <img src='/images/about-us-bg.jpg' alt='carousel' className='w-full h-auto 2xl:h-[700px]' />
                <div className='absolute inset-0 flex justify-center items-center '>
                    <p className='text-white text-center text-[8vw] font-roboto font-bold '>About Us</p>
                </div>
            </div>
            <div className='px-[50px] max-sm:px-[30px] mt-[100px] max-sm:mt-[50px]'>
                <AboutMenuItem />
            </div>
            <div className='px-[50px] max-sm:px-[30px] mt-[100px] max-sm:mt-[50px]'>
                <AboutSpecialProducts />
            </div>
            <div className='px-[50px] max-sm:px-[30px] mt-[100px] max-sm:mt-[50px]'>
                <HappyCustomers />
            </div>
            <div className='mt-[100px] max-sm:mt-[50px]'>
                <JoinUsForm />
            </div>
            <ContactUs />
        </div>
    )
}

export default About
