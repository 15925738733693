import React from 'react'
import CommonTitle from '../../atoms/CommonTitle'
import GallaryItems from '../GallaryItems'

const Products = ({searchQuery}) => {
    return (
        <div loading="lazy">
            <CommonTitle title='Our Products' titleDesc='Best Quality Products' />
            <div className='px-[100px] max-sm:px-[30px]'>
                <GallaryItems searchQuery={searchQuery} />
            </div>
        </div>
    )
}

export default Products
