import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Popover } from "antd";
import { CiSearch } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import { useMediaQuery } from '@mui/material';
import OutsideClickHandler from 'react-outside-click-handler';

import { commonConstants } from '../../utils/constants'
import CircularButton from '../../atoms/CircularButton'
import './popover.scss'


const Header = ({ setSearchQuery, searchQuery }) => {

    const location = useLocation();
    const navigate = useNavigate();

    const { pathname } = location;
    const [openMenu, setOpenMenu] = useState(false)
    const [search, setSearch] = useState(false)

    const isMobile = useMediaQuery('(max-width:1023px)');

    useEffect(() => {
        window.scrollTo(0, 0);
        setSearchQuery('');
        setSearch(false)
    }, [pathname]);

    const content = (
        <div className="pl-5 py-5 flex flex-col gap-[20px] shadow-[0px_4px_84px_0px_#00000026]">
            {commonConstants.headerItems.map((item) => (
                <div className="flex flex-col">
                    <div onClick={() => { setOpenMenu(false); navigate(`${item.path}`) }} className={`text-[14px] sm:text-[19px] font-roboto ${pathname == item.path ? 'text-theme' : 'text-[#292929]'} font-medium  cursor-pointer px-1`}>{item.name}</div>
                </div>
            ))}
        </div>
    )

    return (
        <div className="sticky  top-0 z-20" >
            {!isMobile && <div className='bg-theme-gradient h-[66px] max-sm:h-[50px] flex items-center shadow-[0px_14px_124px_0px_#00000021]'>
                {/* <marquee behavior="scroll" scrolldelay="0" direction="right" width="100%"> */}
                <div className='text-[#fff] flex justify-between w-full px-8 max-lg:px-4'>
                    <div className='flex items-center'>
                        <img src='/svg/phone.svg' className='h-[20px] w-[20px] max-sm:h-[16px] ' alt='phone' />
                        <a href={`tel:${commonConstants.company_number}`} className='pl-[10px] max-sm:pl-[2px] text-[18px] max-sm:text-[14px] font-roboto font-medium'>
                            {commonConstants.company_number}
                        </a>
                    </div>
                    <p className='text-[24px] font-roboto font-bold max-lg:text-[17px] max-sm:text-[17px] max-sm:pl-[30px] max-md:px-[40px] max-md:pl-[80px]'>{commonConstants.company_welcome_title}</p>
                    <div className='flex items-center'>
                        <img src='/svg/email.svg' className='h-[20px] w-[20px]' alt='phone' />
                        <a href={`mailTo:${commonConstants.company_email}`} className='pl-[10px] text-[18px] font-roboto font-medium max-sm:text-[14px]'>
                            {commonConstants.company_email}
                        </a>
                    </div>
                </div>
                {/* </marquee> */}
            </div>}
            <div id="stickyHeader" className='bg-[#fff] h-[80px] max-md:h-[70px] max-lg:h-[80px] flex justify-evenly max-2xl:justify-between pt-[12px] pb-[12px] items-center shadow-[0px_14px_124px_0px_#0000003B;] px-4'>
                <img src='svg/logo.svg' alt='logo' className='w-[243px] h-[65px] max-sm:w-[150px] max-md:w-[180px]  cursor-pointer' onClick={() => navigate("/")} />
                {commonConstants.headerItems.map((item) => (
                    <Link to={`${item.path}`} className={`max-lg:hidden text-[20px] font-roboto ${pathname == item.path ? 'text-theme' : 'text-[#292929]'} font-medium`}>{item.name}</Link>
                ))}
                {!isMobile &&
                    !pathname.includes('/about') && !pathname.includes('/contact') && <div className="bg-white max-lg:hidden flex items-center" >
                        {search && <><div className="border border-[#E62828] mx-4  rounded-xl flex justify-between items-center px-2 py-2 w-[400px] max-2xl:w-[250px]">
                            <input type="text" className="outline-none focus:outline-none p-1 rounded-lg text-[16px] " value={searchQuery} onChange={(e) => {
                                setSearchQuery(e.target.value);
                            }} placeholder="Search for your favourites " />
                            {searchQuery.length >= 1 && <IoIosClose className="mr-0 text-[#E62828] cursor-pointer text-[25px]" onClick={() => setSearch(false)} />}
                        </div>
                        </>
                        }
                        <div onClick={() => { setSearch(!search); }}>
                            <CircularButton src='/svg/search.svg' alt="search" customClassName='mr-[15px] shadow-[0px_4px_24px_0px_#00000054]' />
                        </div>
                    </div>
                }
                <div className='flex relative lg:hidden '>
                    <div onClick={() => { setSearch(!search); }}>
                        {!pathname.includes('/about') && !pathname.includes('/contact') && (
                            <CircularButton src='/svg/search.svg' alt="search" customClassName='mr-[15px] shadow-[0px_4px_24px_0px_#00000054]' />
                        )}
                    </div>
                    <OutsideClickHandler onOutsideClick={() => setOpenMenu(false)}>
                        <Popover content={content}
                            trigger="click"
                            open={openMenu}
                            placement="bottom"
                            overlayStyle={{ width: '100vw', left: 0 }}
                            overlayClassName="custom-popover"
                        >
                            <div onClick={() => setOpenMenu(!openMenu)}>
                                <CircularButton src='/svg/menu.svg' alt="menu" customClassName='lg:hidden shadow-[0px_4px_24px_0px_#00000054]' />
                            </div>
                        </Popover>
                    </OutsideClickHandler>
                </div>
                {search && isMobile &&
                    <div className="bg-white absolute max-sm:top-[130px] max-md:top-[155px]  top-[200px]  z-10 w-full left-0" >
                        <div className="border border-[#E62828] mx-4 mb-2 md:mb-4 rounded-lg flex justify-between items-center px-2">
                            <div className="flex gap-2 items-center w-full">
                                <CiSearch className="text-[#E62828] text-[25px] md:text-[38px] md:ml-4" />
                                <input type="text" className="outline-none focus:outline-none p-[10px] md:py-[16px] rounded-lg text-[15px] md:text-[25px] placeholder:text-[15px] placeholder:md:text-[25px] w-full" value={searchQuery} onChange={(e) => {
                                    setSearchQuery(e.target.value);
                                }} placeholder="Search for your favourites " />
                            </div>
                            {searchQuery.length >= 1 && <IoIosClose className="mr-0 text-[#E62828] cursor-pointer text-[25px] md:text-[38px]" onClick={() => setSearch(!search)} />}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Header
