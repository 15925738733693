import React, { useEffect } from 'react'
import { commonConstants } from '../utils/constants'

const OutletList = ({ searchQuery }) => {

    useEffect(() => {
        if (searchQuery) {
            const element = document.getElementById('outlet-list');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [searchQuery]);

    const filteredOutlets = commonConstants.outlets.filter(outlet =>
        outlet.landmark.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const showInMapClicked = (url) => {
        window.open(url, '_blank');
    };


    return (
        <div id="outlet-list" className='inline-grid max-sm:gap-4 gap-20 grid-cols-2 w-full'>
            {filteredOutlets.length === 0 ? (
                <p className="font-semibold">No outlets found !</p>
            ) : (
                filteredOutlets.map((outlet, idx) => (
                    <div
                        data-aos={idx % 2 === 0 ? 'fade-right' : 'fade-left'}
                        key={idx}
                        className='relative'
                    >
                        <img
                            className='rounded-[50px] max-sm:rounded-[25px] aspect-[0.875] object-cover'
                            src={outlet.image}
                            alt=''
                        />
                        <div className='absolute flex flex-col gap-1 rounded-[35px] max-sm:rounded-[18px] p-6 max-sm:p-2 shadow-[0px_4px_34px_0px_#0000008C] w-[80%] bottom-[10%] left-[10%] bg-white' onClick={() => showInMapClicked(outlet.googleMapLink)}>
                            <span className='text-[2vw] font-[600] max-sm:text-[12px] max-xl:text-[24px]'>
                                {outlet.landmark}
                            </span>
                            <span className='text-[1.3vw] font-[500] max-sm:text-[10px] max-xl:text-[16px]'>
                                {outlet.address}
                            </span>
                        </div>
                    </div>
                ))
            )}
        </div>
    )
}

export default OutletList