import React, { useEffect, useState } from 'react';
import { commonConstants } from '../utils/constants';
import './GalleryItems.scss';
import Loader from '../atoms/Loader/Loader';
import Slider from "react-slick";
import { useMediaQuery } from '@mui/material';

const GallaryItems = ({ searchQuery }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [loading, setLoading] = useState(true);
    const isMobile = useMediaQuery('(max-width:912px)');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const handleTabChange = (key) => {
        setLoading(true);
        setActiveTab(key);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };
    useEffect(() => {
        if (searchQuery) {
            const element = document.getElementById('filtered-items-section');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [searchQuery]);

    const filteredItems = (commonConstants?.gallaryProducts?.find(item => item.key === activeTab)?.items || [])
        .filter(item => item?.title?.toLowerCase().includes(searchQuery?.toLowerCase()));

    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <img data-aos="fade-left" className='z-10 absolute right-[-30px] max-sm:right-[-20px] top-[350px] max-sm:top-[200px] cursor-pointer max-sm:h-[35px] max-lg:h-[45px]' onClick={onClick} src={className.includes('slick-disabled') ? '/svg/disabled-next.svg' : '/svg/enabled-next.svg'} alt="" />
        );
    }

    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <img data-aos="fade-left" className='z-10 absolute left-[-30px] max-sm:left-[-20px] top-[350px] max-sm:top-[200px] cursor-pointer max-sm:h-[35px] max-lg:h-[45px]' onClick={onClick} src={className.includes('slick-disabled') ? '/svg/disabled-previous.svg' : '/svg/enabled-previous.svg'} alt="" />
        );
    }

    const settings = {
        dots: isMobile ? false : true,
        // infinite: true,
        speed: 500,
        rows: 2,
        slidesToShow: 4.5,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        swipeToSlide: true,
        centerPadding: '40px',
        slidesToScroll: 1.5,
        autoplay: true,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 640, // 'sm' breakpoint
                settings: {
                    slidesToShow: 1.5
                }
            },
            {
                breakpoint: 900, // 'sm' breakpoint
                settings: {
                    slidesToShow: 1.8
                }
            },
            {
                breakpoint: 1024, // 'sm' breakpoint
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 1200, // 'md' breakpoint
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 1380, // 'lg' breakpoint
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1480,
                settings: {
                    slidesToShow: 3.5
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3.5
                }
            }
        ]
    };

    return (
        <>
            <div id='filtered-items-section' className='grid gap-[2%] max-sm:gap-[12px] grid-cols-6 w-full max-xl:grid-cols-3 mb-10 max-sm:mb-6 max-lg:mb-16 max-2xl:grid-cols-4 max-2xl:gap-[4%]' >
                {commonConstants.gallaryProducts?.map((item) => {
                    return (
                        <div key={item.key} onClick={() => handleTabChange(item.key)} className={`${activeTab === item.key ? 'border-[#EA6121] border-2' : 'border-[#C1C1C1] border'} cursor-pointer rounded-[40px] max-sm:rounded-[30px] flex flex-col items-center pt-9 px-9 max-sm:px-[7px] max-sm:pt-[7px] max-lg:pt-7 max-lg:px-7 max-md:pt-5 max-md:px-5 hover:scale-105`}>
                            <img loading="lazy" src={item.image} alt='product' className='rounded-[30px] max-md:rounded-[20px]' />
                            <p className='text-[1.3vw] max-xl:text-[20px] max-lg:text-[18px]  max-sm:text-[12px] text-[#010101] font-roboto font-medium  text-center py-2'>{item.title}</p>
                        </div>
                    )
                })}
            </div>
            {!loading ?
                (filteredItems.length ?
                    <Slider {...settings}>
                        {filteredItems?.map((item) => (
                            <div key={item.key} className='slider-item'>
                                <div className='relative h-[324px] max-sm:h-[150px] rounded-[0px_25%_0px_25%] w-[200px] md:w-[300px] mt-[20px] md:mt-[50px]' data-aos='zoom-in'>
                                    <img
                                        loading="lazy"
                                        src={item.image}
                                        alt='menu-item'
                                        className='rounded-[0px_25%_0px_25%] w-[200px] md:w-[300px] object-cover h-[324px] max-sm:h-[150px]'
                                    />
                                    <div className='absolute p-4 text-center flex flex-col items-center justify-end bottom-0 w-full h-full bg-gradient-to-t from-black to-transparent to-[50%] rounded-[0px_25%_0px_25%] '>
                                        <p className='text-[2vw] max-sm:text-[12px] max-lg:text-[20px] mb-[6%] text-[#fff] font-roboto font-semibold'>
                                            {item?.title}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                    :
                    <div className='mt-[6%] text-center font-[300] text-[30px] max-sm:text-[12px]'>- No Items Found -</div>)
                : <Loader />
            }
        </>
    )
}

export default GallaryItems
