import React from 'react'
import Home from '../organisms/Home'
import About from '../organisms/About'
import Outlets from '../organisms/Outlets'
import Contact from '../organisms/Contact'
import Gallery from '../organisms/Gallery'
import { Route, Routes } from 'react-router-dom'

const AppRoutes = ({searchQuery}) => {
    return (
        <Routes>
            <Route path='/' element={<Home searchQuery={searchQuery} />} />
            <Route path='/about' element={<About />} />
            <Route path='/outlets' element={<Outlets searchQuery={searchQuery} />} />
            <Route path='/gallery' element={<Gallery searchQuery={searchQuery} />} />
            <Route path='/contact' element={<Contact />} />
        </Routes>
    )
}

export default AppRoutes
