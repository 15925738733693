import React from 'react'
import Slider from 'react-slick';
import { commonConstants } from '../utils/constants';

const HappyCustomers = () => {
    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <img data-aos="fade-left" className='absolute right-[50px] max-lg:right-[20px] top-[-80px] max-sm:top-[-50px] max-lg:top-[-62px] cursor-pointer max-sm:h-[35px] max-lg:h-[45px]' onClick={onClick} src={className.includes('slick-disabled') ? '/svg/disabled-next.svg' : '/svg/enabled-next.svg'} alt="" />
        );
    }

    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <img data-aos="fade-left" className='absolute right-[130px] top-[-80px]  max-sm:right-[60px] max-lg:right-[80px]  max-sm:top-[-50px] max-lg:top-[-62px] cursor-pointer max-sm:h-[35px] max-lg:h-[45px]' onClick={onClick} src={className.includes('slick-disabled') ? '/svg/disabled-previous.svg' : '/svg/enabled-previous.svg'} alt="" />
        );
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2.5,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        swipeToSlide: true,
        centerPadding: '40px',
        autoplay: true,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 640, // 'sm' breakpoint
                settings: {
                    slidesToShow: 1.05
                }
            },
            {
                breakpoint: 1024, // 'md' breakpoint
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };
    return (
        <div className='flex flex-col gap-[30px] max-sm:gap-[7px] max-lg:gap-[10px] mt-[100px] max-sm:mt-[50px] relative happy-customer-slides mr-[-50px]'>
            <span data-aos="fade-right" className='font-[400] text-[33px] max-sm:text-[15px] max-lg:text-[22px] lg:leading-[25px] text-[#292929]'>
                Happy Customers
            </span>
            <span data-aos="fade-right" className='font-[600] text-[35px] max-sm:text-[16px] max-lg:text-[24px] lg:leading-[46px] text-theme max-sm:mb-[10px]'>
                What customers say about us
            </span>
            <div className="max-sm:px-[30px]">
                <Slider {...settings}>
                    {
                        commonConstants.happyCustomers.map((customer, idx) => {
                            return (
                                <div className='p-[3%]' key={idx}>
                                    <div className='flex flex-col gap-4 shadow-[0px_4px_84px_0px_#00000026] rounded-[30px] p-[8%]'>
                                        <img className='w-[10%]' src='/svg/quote-start.svg' alt="" />
                                        <span className='font-[600] text-[1.5vw] max-sm:text-[12px] max-lg:text-[17px] max-xl:text-[19px]'>{customer.description}</span>
                                        <img className='w-[10%] ml-auto' src='/svg/quote-end.svg' alt="" />
                                        <div className='flex items-center gap-6'>
                                            {/* <img className='w-[20%] rounded-[50%] object-cover aspect-square max-sm:w-[80px]' src={customer.image} alt="" /> */}
                                            <span className='font-[600] text-[1.5vw] max-sm:text-[12px] max-lg:text-[17px] max-xl:text-[19px]'>{customer.name}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>

        </div>
    )
}

export default HappyCustomers