import React from 'react'

const CommonTitle = ({ title, titleDesc }) => {
    return (
        <div className='my-[50px] max-lg:mb-[20px] mx-[100px] max-sm:mx-[30px] '>
            <p className='text-[35px] text-[#292929] font-roboto font-normal max-sm:text-[16px] max-xl:text-[25px]' data-aos='fade-right'>{title}</p>
            <p className='text-[45px] text-[#EA6121] font-roboto font-semibold max-sm:text-[20px] max-xl:text-[35px]' data-aos='fade-right'>{titleDesc}</p>
        </div>
    )
}

export default CommonTitle
