import React, { useState } from 'react'
import { useMediaQuery } from '@mui/material';
import ButtonComponent from '../atoms/Button'


const AboutSpecialProducts = () => {
    const [isExpanded1, setIsExpanded1] = useState(false);
    const [isExpanded2, setIsExpanded2] = useState(false);

    const isMobile = useMediaQuery('(max-width:767px)');
    const isTablet = useMediaQuery('(min-width:768px) and (max-width:830px)');


    const handleReadMore1 = () => {
        setIsExpanded1(!isExpanded1);
    };

    const handleReadMore2 = () => {
        setIsExpanded2(!isExpanded2);
    };

    return (
        <div className='flex flex-col gap-[150px] max-sm:gap-[7px] '>
            <div className='flex flex-col gap-[60px] max-lg:gap-[10px]'>
                <div className='flex gap-14 max-sm:gap-3 items-start justify-between'>
                    <div className='flex flex-col gap-3 max-lg:gap-2 w-[45%]'>
                        <span data-aos="fade-right" className='text-[22px] max-sm:text-[15px] text-[#292929] font-[400]'>Special Product</span>
                        <span data-aos="fade-right" className='text-[35px] max-sm:text-[20px] text-theme font-[600]'>Gulab Halwa</span>
                        <span data-aos="fade-right" className={`text-[20px] max-sm:text-justify max-sm:text-[12px] text-[#0F172A] font-[500] mt-5 max-sm:mt-2 ${isExpanded1 ? '' : 'line-clamp-2'}`}>
                            Gulab Halwa features a mix of Tahini, milk, rose water,
                            orange blossom, rose petal & sugar which creates a
                            mesmerizing texture. It is a delicious dessert item that
                            is best when chilled.
                            <br />
                            {(!isMobile && !isTablet) && <p>
                                Gulab Halwa made with semolina, Rose flavor, filled with
                                yummy cream, and topped with pistachios and beautiful
                                edible rose petals.
                            </p>}
                        </span>
                        {(!isMobile && !isTablet) &&
                            <ButtonComponent data-aos="fade-right" iconClassName="absolute right-[10px] max-sm:w-[20px]" iconPath="/svg/right-white-arrow.svg" labelClassname="text-[24px] max-sm:text-[14px] ml-[-25px] leading-[24px] font-[600]" inputStyles={{ minWidth: isMobile ? '130px' : '270px', width: isMobile ? '130px' : '270px', height: isMobile ? '35px' : '60px', boxShadow: 'none', marginTop: isMobile ? '10px' : '60px' }} label={isExpanded1 ? "Read less" : "Read more"} onClick={handleReadMore1} />
                        }
                        {(isMobile || isTablet) && !isExpanded1 && <ButtonComponent data-aos="fade-right" iconClassName="absolute right-[10px] max-lg:w-[18px] " iconPath="/svg/right-white-arrow.svg" labelClassname="text-[24px] max-lg:text-[18px] max-sm:text-[14px] ml-[-25px] leading-[24px] font-[600]" inputStyles={{ minWidth: isMobile ? '130px' : isTablet ? ' 160px' : '270px', width: isMobile ? '130px' : isTablet ? ' 160px' : '270px', height: isTablet || isMobile ? '35px' : '60px', boxShadow: 'none', marginTop: isMobile || isTablet ? '10px' : '60px' }} label={isExpanded1 ? "Read less" : "Read more"} onClick={handleReadMore1} />}
                    </div>
                    <img data-aos="fade-left" className='w-[45%] aspect-square rounded-[90px] max-sm:rounded-[40px] object-bottom object-cover' src='/images/BannerImages/Gulab-Halwa1.webp' alt="" />
                </div>
                {(isMobile || isTablet) && isExpanded1 && <p className="max-sm:text-[12px] max-lg:text-[20px] text-[#0F172A] font-[500] text-justify">
                    Gulab Halwa made with semolina, Rose flavor, filled with
                    yummy cream, and topped with pistachios and beautiful
                    edible rose petals.
                </p>}
                {(isMobile || isTablet) && isExpanded1 && <ButtonComponent data-aos="fade-right" iconClassName="absolute right-[10px] max-lg:w-[18px]" iconPath="/svg/right-white-arrow.svg" labelClassname="text-[24px] max-sm:text-[14px] max-lg:text-[18px] ml-[-25px] leading-[24px] font-[600]" inputStyles={{ minWidth: isMobile ? '130px' : isTablet ? ' 160px' : '270px', width: isMobile ? '130px' : isTablet ? ' 160px' : '270px', height: isTablet || isMobile ? '35px' : '60px', boxShadow: 'none', marginTop: isMobile || isTablet ? '10px' : '60px' }} label={isExpanded1 ? "Read less" : "Read more"} onClick={handleReadMore1} />}

                <div className='flex gap-14 max-lg:gap-3 items-start justify-between max-lg:mt-7'>
                    <img data-aos="fade-right" className='w-[45%] aspect-square rounded-[90px] max-sm:rounded-[40px] object-bottom object-cover' src='/images/Bengali-Mithai/Lassi(7).webp' alt="" />
                    <div className='flex flex-col gap-3 max-sm:gap-2 w-[45%]'>
                        <span data-aos="fade-left" className='text-[22px] max-sm:text-[15px] text-[#292929] font-[400]'>Special Product</span>
                        <span data-aos="fade-left" className='text-[35px] max-sm:text-[20px] text-theme font-[600]'>Lassi</span>
                        <span data-aos="fade-left" className={`text-[20px] max-sm:text-justify max-sm:text-[12px] text-[#0F172A] font-[500] mt-5 max-sm:mt-2 ${isExpanded2 ? '' : 'line-clamp-2'}`}>
                            Lassi from Gulab Halwa Wala is a popular Indian beverage enjoyed for its refreshing qualities and creamy texture.
                            {(!isMobile && !isTablet) && <p>
                                Served in a tall glass, it's made by blending yogurt with milk, sugar, and flavored with cardamom or rose water.
                                The result is a smooth, sweet drink that cools you down on hot days or pairs perfectly with spicy meals.
                                Topped with a dollop of creamy malai and occasionally garnished with crushed nuts like almonds or pistachios, it's a delightful treat loved by many for its balance of flavors and soothing properties.
                            </p>}
                        </span>
                        {(!isMobile && !isTablet) && <ButtonComponent data-aos="fade-right" iconClassName="absolute right-[10px] max-sm:w-[20px]" iconPath="/svg/right-white-arrow.svg" labelClassname="text-[24px] max-sm:text-[14px] ml-[-25px] leading-[24px] font-[600]" inputStyles={{ minWidth: isMobile ? '130px' : '270px', width: isMobile ? '130px' : '270px', height: isMobile ? '35px' : '60px', boxShadow: 'none', marginTop: isMobile ? '10px' : '60px' }} label={isExpanded2 ? "Read less" : "Read more"} onClick={handleReadMore2} />}
                        <div>
                            {(isMobile || isTablet) && !isExpanded2 && <ButtonComponent data-aos="fade-right" iconClassName="absolute right-[10px] max-lg:w-[18px] " iconPath="/svg/right-white-arrow.svg " labelClassname="text-[24px] max-lg:text-[18px] max-sm:text-[14px] ml-[-25px] leading-[24px] font-[600]" inputStyles={{ minWidth: isMobile ? '130px' : isTablet ? ' 160px' : '270px', width: isMobile ? '130px' : isTablet ? ' 160px' : '270px', height: isTablet || isMobile ? '35px' : '60px', boxShadow: 'none', marginTop: isMobile || isTablet ? '10px' : '60px' }} label={isExpanded1 ? "Read less" : "Read more"} onClick={handleReadMore2} />}
                        </div>
                    </div>
                </div>
                {(isMobile || isTablet) && isExpanded2 && <p className="max-sm:text-[12px] max-lg:text-[20px] text-[#0F172A] font-[500] text-justify">
                    Topped with a dollop of creamy malai and occasionally garnished with crushed nuts like almonds or pistachios, it's a delightful treat loved by many for its balance of flavors and soothing properties.
                </p>}
                <div className="flex justify-end">
                    {(isMobile || isTablet) && isExpanded2 && <ButtonComponent data-aos="fade-right" iconClassName="absolute right-[10px] max-lg:w-[18px]" iconPath="/svg/right-white-arrow.svg" labelClassname="text-[24px] max-lg:text-[18px] max-sm:text-[14px] ml-[-25px] leading-[24px] font-[600]" inputStyles={{ minWidth: isMobile ? '130px' : isTablet ? ' 160px' : '270px', width: isMobile ? '130px' : isTablet ? ' 160px' : '270px', height: isTablet || isMobile ? '35px' : '60px', boxShadow: 'none', marginTop: isMobile || isTablet ? '10px' : '60px' }} label={isExpanded2 ? "Read less" : "Read more"} onClick={handleReadMore2} />}
                </div>
            </div>
        </div>
    )
}

export default AboutSpecialProducts
















