import React from 'react'
import { commonConstants } from '../../utils/constants'

const CardMenuItem = () => {
  return (
    <div className='grid grid-cols-2 gap-8 justify-items-center sm:pt-[20px] lg:pb-[50px] px-[50px] max-sm:px-6 '>
      {commonConstants?.homeCardMenuItems?.map((item) => {
        return <div key={item.key} className='w-[630px] h-[668px] relative max-sm:w-[150px] max-sm:h-[150px] max-2xl:w-[500px] max-2xl:h-[500px]  max-xl:w-[400px] max-xl:h-[400px] max-lg:h-[300px] max-lg:w-[300px]' data-aos="zoom-in-up">
          <div className='absolute inset-0 bg-gradient-to-t from-black to-transparent rounded-[50px] max-sm:rounded-[20px] max-2xl:rounded-[40px] max-sm:w-[150px] max-sm:h-[150px] max-lg:h-[300px] max-lg:w-[300px] '></div>
          <img src={item.bg_img} alt='menu' className='rounded-[50px] max-sm:rounded-[20px] h-full max-sm:w-[150px] max-sm:h-[150px] max-2xl:w-[500px] max-2xl:h-[500px] max-xl:w-[400px] max-xl:h-[400px] max-lg:h-[300px] max-lg:w-[300px]' />
          <div className='absolute inset-0 flex flex-col justify-end mb-2 w-full p-5 max-sm:p-[8px]'>
            <p className='text-[40px] text-[#fff] font-roboto w-full font-medium max-sm:text-[14px] max-2xl:text-[33px] '>{item.name}</p>
            <p className='text-[24px] text-[#fff] font-roboto  max-sm:text-[11px]  max-2xl:text-[19px] '>{item.description}</p>
          </div>
        </div>

      })}
    </div>
  )
}

export default CardMenuItem
