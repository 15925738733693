import React, { useEffect, useState } from 'react'

//import { useMediaQuery } from '@mui/material';

import CommonTitle from '../../atoms/CommonTitle'
import { commonConstants } from '../../utils/constants'
import Category from '../../atoms/Catgeory'
import Loader from '../../atoms/Loader/Loader'

const TopPicks = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [loading, setLoading] = useState(true);

    // const isTablet = useMediaQuery('(min-width:800px) and (max-width:830px)');
    // const isTablet1 = useMediaQuery('(min-width:850px) and (max-width:860px)');
    // const isTablet2 = useMediaQuery('(min-width:910px) and (max-width:915px)');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const handleTabChange = (key) => {
        setLoading(true);
        setActiveTab(key);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <div className='max-sm:px-0 px-[100px] max-2xl:px-[0px]'>
            <CommonTitle title='People’s Choice' titleDesc='Top Picks of Products' />
            <div loading="lazy" className='grid grid-cols-4 max-md:grid-cols-2 max-md:gap-y-6 justify-items-center pb-20 max-xl:pb-10 max-sm:pb-10 max-sm:gap-y-6 max-2xl:px-[50px] max-sm:px-[20px]'>
                {commonConstants.topPicksCategory?.map((item) => {
                    return <Category activeTab={item.key === activeTab} onclick={() => handleTabChange(item.key)} key={item.key} bgColor={item.bgColor} image={item.image} title={item.title} outlineColor={item.outlineColor} textColor={item.txtColor} />
                })}
            </div>
            {!loading ?
                (commonConstants.topPicksCategory.find(item => item.key === activeTab)?.items || []).length ?
                    (
                        <div className='flex items-center justify-center max-2xl:mx-[70px] max-sm:mx-[30px]'>
                            <div data-aos="fade-down-right" className='grid sm:grid-cols-3 grid-cols-2 max-xs:gap-10 max-md:gap-12 gap-32 mt-0 max-2xl:gap-16 max-xl:gap-12 mb-8'>
                                {commonConstants.topPicksCategory.find(item => item.key === activeTab)?.items.map((item) => {
                                    return (
                                        <div key={item.key} >
                                            <div className="border-[3px] lg:border-[4px] border-dashed border-[#F3AF93] rounded-[50%] h-[405px] w-[405px] max-2xl:h-[340px] max-2xl:w-[340px] max-xl:h-[280px] max-xl:w-[280px] max-lg:h-[205px] max-lg:w-[205px]  max-md:h-[165px] max-md:w-[165px] max-xs:h-[135px] max-xs:w-[135px]  ">
                                                <img alt='item' className={`rounded-[50%] p-2 h-[395px] w-[395px] max-xs:h-[130px] max-xs:w-[130px] max-md:h-[160px] max-md:w-[160px]  max-lg:h-[200px] max-lg:w-[200px] max-xl:h-[270px] max-xl:w-[270px] max-2xl:h-[330px] max-2xl:w-[330px]`} src={item.image} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                    : <div className='mt-[6%] text-center font-[300] text-[30px]'>- No Products Found -</div>
                : <Loader />
            }
        </div>
    )
}

export default TopPicks
