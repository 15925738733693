import { Carousel } from 'antd'
import React from 'react'

const CarouselBanner = ({ carouselList }) => {

  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };
  return (
    <Carousel afterChange={onChange} autoplay={true}>
      {carouselList?.map((item) => (
        <div key={item.id} className='relative ' data-aos="zoom-in">
          <div className='absolute inset-0 bg-black opacity-70 '></div>
          <div className="flex justify-center">
            <img src={item.image} alt='carousel' className='h-[550px] max-sm:h-[300px] max-md:h-[350px] max-xl:h-[500px] w-full  object-contain' />
          </div>
          <div className='absolute inset-0 flex justify-center items-center'>
            <p className='text-white text-center text-[100px] font-roboto font-bold md:px-[140px] max-sm:text-[24px] max-lg:text-[50px]  max-xl:text-[65px] max-2xl:text-[70px] mt-[150px] md:mt-[250px]'>{item.title}</p>
          </div>
        </div>
      ))}
    </Carousel>
  )
}

export default CarouselBanner
