import React from 'react'

const CircularButton = ({ src, alt, customClassName }) => {
    return (
        <button className={`bg-theme-gradient h-[53px] w-[53px] max-sm:h-[40px] max-sm:w-[40px] max-md:h-[50px] max-md:w-[50px] max-lg:h-[50px] max-lg:w-[50px] flex justify-center items-center rounded-full ${customClassName}`}>
            <img src={src} alt={alt} className='h-[25px] w-[25px] max-sm:h-[20px] max-sm:w-[20px] max-md:h-[25px] max-md:w-[25px] max-lg:h-[20px] max-lg:w-[20px]' />
        </button>
    )
}

export default CircularButton
