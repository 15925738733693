import React from 'react'
import CarouselBanner from '../atoms/CarouselBanner'
import { commonConstants } from '../utils/constants'
import CardMenuItem from '../molecules/Home/CardMenuItem'
import JoinUsForm from './JoinUsForm'
import ContactUs from '../molecules/ContactUs'
import Products from '../molecules/Home/Products'
import TopPicks from '../molecules/Home/TopPicks'

const Home = ({ searchQuery }) => {
  return (
    <div>
      <CarouselBanner carouselList={commonConstants.homeCarousel} />
      <div className='flex justify-center bg-[rgba(_248_248_248)]'>
        <img src='/svg/logo-banner.svg' alt='logo-banner'  />
      </div>
      <div>
        <div className="bg-home-banner-border h-[65px] mt-[-28px]"></div>
        <div className='max-lg:pb-20 max-sm:pb-10 bg-[#FFD285] lg:flex justify-center '>
          <img src='/svg/gulab-halwa-sticker.svg' alt='gulab-halwa-sticker' data-aos='fade-right' className="max-lg:w-full max-sm:h-[325px] max-lg:h-[325px]"/>
          <div className='lg:w-[35%] flex flex-col items-center justify-center lg:pr-[10px]'>
            <p className='text-[40px] text-[#4A2E1F] font-medium font-roboto pb-[25px] max-sm:text-[16px] max-sm:text-center max-2xl:text-[26px] max-lg:px-8' data-aos='fade-left'>
              The Taste of Royal India, perfected over generations <br />
              Gulab Halwa Wala, sweetening celebrations since 1970.
            </p>
            <p className='text-[21px] text-[#4A2E1FCC] font-roboto max-sm:text-[12px] max-lg:px-8 max-lg:text-justify max-2xl:text-[17px] max-lg:text-[19px]' data-aos='fade-left'>
              We work from the heart, with passion to craft authentic sweets and
              savories with an inimitable character that’s truly the taste of
              royal India. From lavish kaju sweets, to pure ghee delicacies and
              freshly made savories, every morsel is imagined and created with a
              single intent, to deliver joy - true Anand.
            </p>
          </div>
        </div>
        <div className="bg-home-banner-border h-[65px] rotate-180"></div>
      </div>
      <CardMenuItem />
      <Products searchQuery={searchQuery} />
      <TopPicks />
      <JoinUsForm />
      <ContactUs />
    </div>
  )
}

export default Home
